import React from "react"
import { graphql } from "gatsby"

import SEO from "../../../components/shell/seo"
import Layout from "../../../components/shell/layout"
import TagsPage from "../../../components/blog/pageContents/TagsPage"

function TagDetailWrapper(props) {
  return (
    <Layout>
      <SEO title="Tin Tức" />
      <TagsPage
        data={props.data.allBlogJson.nodes}
        tags={props.pageContext.tags}
      />
    </Layout>
  )
}

export default TagDetailWrapper

export const query = graphql`
  query($tags: String) {
    allBlogJson(
      filter: { tags: { eq: $tags } }
      sort: { fields: order, order: DESC }
    ) {
      nodes {
        date
        tags
        title
        news
        id
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
        summary
        content
        author
      }
    }
  }
`
